import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from './core/core.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
// import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { ErrorResponseInterceptor } from './interceptors/error-response-interceptor';

// function initializekeycloak(keycloak: KeycloakService) {
//   return () =>
//     keycloak.init({
//       config: {
//         url: 'https://acessopoa-hom.procempa.com.br/auth/',
//         realm: 'acessopoa',
//         clientId: 'ficai'
//       },
//       bearerPrefix: 'Bearer',
//       initOptions: {
//         onLoad: 'login-required',
//         silentCheckSsoRedirectUri:
//           window.location.origin + '/assets/keycloak.html'
//       },

//       enableBearerInterceptor: true,
//       loadUserProfileAtStartUp: true
//     });
// }

function initializekeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.KEYCLOAK_URL,
        realm: environment.KEYCLOAK_REALM,
        clientId: environment.KEYCLOAK_CLIENT
      },
      bearerPrefix: 'Bearer',
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/keycloak.html'
      },

      enableBearerInterceptor: true,
      loadUserProfileAtStartUp: true
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    CoreModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BlockUIModule.forRoot({
      message: 'Carregando...',
      delayStart: 500,
      delayStop: 500
    }),
    BlockUIHttpModule.forRoot()
  ],

  providers: [
    DatePipe,
    HttpClient,
    {
      provide: APP_INITIALIZER,
      useFactory: initializekeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorResponseInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
