import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
  Observable,
  Subject,
  Subscription,
  debounceTime,
  distinctUntilChanged
} from 'rxjs';

@Component({
  selector: 'app-live-search-input',
  templateUrl: './live-search-input.component.html',
  styleUrls: ['./live-search-input.component.scss']
})
export class LiveSearchInputComponent implements OnInit, OnDestroy {
  @Input() initialValue: string = '';
  @Input() placeholder: string = '';
  @Input() id: string = '';
  @Input() debounceTime = 300;
  @Input() icon: IconDefinition | undefined = undefined;

  @Output() textChange = new EventEmitter<string>();

  subscriptions: Subscription[] = [];
  inputValue: Subject<string>;
  trigger: Observable<string>;

  constructor() {
    this.inputValue = new Subject<string>();
    this.trigger = this.inputValue.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged()
    );
  }

  ngOnInit() {
    const subscription = this.trigger.subscribe((currentValue) => {
      this.textChange.emit(currentValue);
    });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  onInput(e: any) {
    this.inputValue.next(e.target.value);
  }
}
