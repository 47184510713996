import { Order } from './Order';

export class PaginationQuery {
  page: number;
  pageSize: number;
  search?: string;
  direction?: Order;
  atributo?: string;
  filters?: {
    [key: string]: any;
  };

  constructor() {
    this.page = 0;
    this.pageSize = 10;
    this.direction = Order.ASC;
  }
}
