import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IBreadCrumb } from '../components/breadcrumb/breadcrumb.component';
import { ActivatedRoute, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbs: IBreadCrumb[] = [];
  backendUrl = environment.BASE_URL;
  breadcrumbList = new Subject<IBreadCrumb[]>();
  breadcrumb = new Subject<IBreadCrumb>();

  constructor(private http: HttpClient) {
    this.breadcrumbList.subscribe((bcList) => {
      this.breadcrumbs = bcList;
    });

    this.breadcrumb.subscribe({
      next: (bc) => {
        this.breadcrumbs.push(bc);
        this.breadcrumbList.next(this.breadcrumbs);
      }
    });
  }

  buildBreadcrumbsFromRoot(activatedRoute: ActivatedRoute) {
    this.buildBreadcrumbs(activatedRoute.root, '');
  }

  // Méto recursivo para construir breadcrumb inicial
  private buildBreadcrumbs(activatedRoute: ActivatedRoute, url: string = '') {
    const children: ActivatedRoute[] = activatedRoute.children;

    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');

      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const routeData: Data = child.snapshot.data;

      if (routeData['breadcrumb']) {
        const item = {
          label: routeData['breadcrumb'],
          url: url,
          hidden: routeData['breadcrumbHidden']
        };

        this.addItem(item);
      }

      this.buildBreadcrumbs(child, url);
    }
  }

  clearBreadcrumb() {
    this.breadcrumbList.next([]);
  }

  addItem(bc: IBreadCrumb) {
    this.breadcrumb.next(bc);
  }

  cheangeByUrl(url: string, label: string) {
    const tempList = this.breadcrumbs;
    this.clearBreadcrumb();

    tempList.forEach((bc) => {
      if (bc.url === url) {
        bc.label = label;
      }
      this.addItem(bc);
    });
  }
}
