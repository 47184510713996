import { Component, Input } from '@angular/core';
import { TableHeader } from '../../types/TableHeader';
import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { Order } from '../../types/Order';

@Component({
  selector: 'app-table-generic',
  templateUrl: './table-generic.component.html',
  styleUrls: ['./table-generic.component.scss']
})
export class TableGenericComponent {
  @Input() paginatedResult!: Array<any>;
  @Input() headers: TableHeader[] = [];
  @Input() loading: boolean = true;

  readonly sortIcons = { default: faSort, asc: faSortUp, desc: faSortDown };

  changeOrder(header: TableHeader) {
    header.ordem = header.ordem === Order.ASC ? Order.DESC : Order.ASC;
    // this.paginatedResult.query.atributo = header.atributo;
    // this.paginatedResult.query.direction = header.ordem;
    // this.search.emit();
  }
}
