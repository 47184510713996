import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ConfiguracaoComponent } from './pages/configuracao/configuracao.component';
import { DadosComponent } from './pages/configuracao/dados/dados.component';
import { InfoUserComponent } from './components/info-user/info-user.component';
import { NavBarManagerComponent } from './components/nav-bar-manager/nav-bar-manager.component';
import { PerfisComponent } from './pages/configuracao/perfis/perfis.component';

@NgModule({
  declarations: [
    HeaderComponent,
    NavBarComponent,
    HomeComponent,
    FooterComponent,
    BreadcrumbComponent,
    ConfiguracaoComponent,
    DadosComponent,
    InfoUserComponent,
    NavBarManagerComponent,
    PerfisComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    RouterModule,
    FontAwesomeModule,
    HeaderComponent,
    NavBarComponent,
    FooterComponent,
    BreadcrumbComponent,
    NavBarManagerComponent,
    InfoUserComponent
  ]
})
export class CoreModule {}
