import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpfFormatter'
})
export class CpfFormatterPipe implements PipeTransform {
  transform(value: string): string {
    return `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(
      6,
      9
    )}-${value.slice(9, 11)}`;
  }
}
