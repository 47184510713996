import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-error-msg',
  templateUrl: './error-msg.component.html',
  styleUrls: ['./error-msg.component.scss']
})
export class ErrorMsgComponent {
  @Input() control!: AbstractControl;
  text!: string;
  iconeErro = faXmarkCircle;

  getErrorMsg(): boolean {
    if (this.control.hasError('required') && this.control.touched) {
      this.text = `Campo obrigatório`;
      return true;
    }

    if (this.control.hasError('digit') && this.control.touched) {
      this.text = `número do registro de contribuinte individual brasileiro (CPF) inválido`;
      return true;
    }

    if (this.control.hasError('email') && this.control.touched) {
      this.text = `E-mail inválido`;
      return true;
    }

    if (this.control.hasError('length') && this.control.touched) {
      this.text = `número que zero`;
      return true;
    }

    if (this.control.hasError('equalDigits') && this.control.touched) {
      this.text = `número do registro de contribuinte individual brasileiro (CPF) inválido`;
      return true;
    }

    if (this.control.hasError('idade') && this.control.touched) {
      this.text = `A idade do estudante deve ser menor que 18 anos`;
      return true;
    }

    if (this.control.hasError('minlength') && this.control.touched) {
      this.text = `Tamanho mínimo: 11 caracteres`;
      return true;
    }

    if (this.control.hasError('maxlength') && this.control.touched) {
      this.text = `Campo atingiu máximo permitido`;
      return true;
    }

    if (this.control.hasError('cepInvalido') && this.control.touched) {
      this.text = `CEP inválido`;
      return true;
    }

    if (this.control.hasError('caracterInvalido') && this.control.touched) {
      this.text = `Caractere inválido`;
      return true;
    }

    if (this.control.hasError('visitaData') && this.control.touched) {
      this.text = `Data deve ser inferior ou igual a data atual`;
      return true;
    }

    if (this.control.hasError('escola') && this.control.touched) {
      this.text = `usuário já possui perfil cadastrado na escola`;
      return true;
    }

    if (this.control.hasError('rae') && this.control.touched) {
      this.text = `usuário já possui perfil cadastrado no município`;
      return true;
    }

    if (this.control.hasError('date') && this.control.touched) {
      this.text = `Valor ano deve ter 4 dígitos`;
      return true;
    }

    if (this.control.hasError('pattern') && this.control.touched) {
      this.text = 'Apenas digitos são permitidos';
      return true;
    }

    return false;
  }
}
