import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Usuario } from 'src/app/core/model/perfilGeneric';
import { CoreService } from 'src/app/core/services/core.service';
import { HandlerErrorService } from 'src/app/shared/services/handler-error.service';

@Component({
  selector: 'app-dados',
  templateUrl: './dados.component.html',
  styleUrls: ['./dados.component.scss']
})
export class DadosComponent implements OnInit {
  form!: FormGroup;
  @Input() user?: Usuario;
  faPenToSquare = faPenToSquare;
  faXmark = faXmark;
  hidden = true;
  @Output() emitUser = new EventEmitter();

  constructor(
    private coreService: CoreService,
    private msg: HandlerErrorService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.email])
    });
  }

  edit() {
    this.form.get('email')?.setValue(this.user?.emailContato);
    this.hidden ? (this.hidden = false) : '';
  }

  save() {
    this.coreService
      .updateEmail(this.form.value.email)
      .subscribe((user: Usuario) => {
        if (user !== undefined) {
          this.user = user;
          this.hidden = true;
          this.eventUser(user);
          this.msg.showSuccess();
        }
      });
  }

  eventUser(user: Usuario) {
    this.emitUser.emit(user);
  }

  cancel() {
    this.hidden = true;
  }
}
