import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { BreadcrumbService } from '../../services/breadcrumb.service';

export interface IBreadCrumb {
  label: string;
  url: string;
  hidden: boolean;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: IBreadCrumb[] = [];
  hidden: boolean = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.breadcrumbList.subscribe((bcList) => {
      this.breadcrumbs = bcList;
      this.hidden = !!this.breadcrumbs.at(-1)?.hidden;
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrumbService.clearBreadcrumb();
        this.breadcrumbService.buildBreadcrumbsFromRoot(this.activatedRoute);
      });
  }
}
