<nav class="br-pagination" aria-label="Paginação de resultados" >

  <div class="pagination-per-page">
    <label for="resultadosPorPagina">Exibir</label>
    <select class="form-select w-auto" (change)="setPageSize($event.target!)" id="resultadosPorPagina">
      <option
        [attr.value]="10"
        [attr.selected]="
          10 === this.paginatedResult.query.pageSize ? true : undefined
        "
      >
        10
      </option>
      <option
        [attr.value]="20"
        [attr.selected]="
          20 === this.paginatedResult.query.pageSize ? true : undefined
        "
      >
        20
      </option>
      <option
        [attr.value]="50"
        [attr.selected]="
          50 === this.paginatedResult.query.pageSize ? true : undefined
        "
      >
        50
      </option>
    </select>
  </div>

  <span class="br-divider d-none d-sm-block mx-3" aria-hidden="true"></span>
  <div class="pagination-information d-none d-sm-flex">    
    <ng-container *ngIf="paginatedResult.collectionSize > 0">
      <span class="current">
        {{ 1 + paginatedResult.query.page * paginatedResult.query.pageSize }}
      </span>
      &nbsp;-&nbsp;
        <span class="per-page">
        {{
          paginatedResult.query.page * paginatedResult.query.pageSize +
            paginatedResult.data.length
        }}
        </span>
        &nbsp;de&nbsp;
    </ng-container>
    <span class="total">{{ paginatedResult.collectionSize }}</span>&nbsp;itens
  </div>
  
  <div class="d-flex ml-auto">
    <div class="pagination-go-to-page d-none d-sm-flex">
      <label for="numPagina">Página</label>
      <select class="form-select w-auto" (change)="toPage($event.target!)" id="numPagina">
        <option
          [attr.value]="page"
          *ngFor="let page of getPagesList()"
          [attr.selected]="
            page === this.paginatedResult.query.page ? true : undefined
          "
        >
          {{ page + 1 }}
        </option>
      </select>
    </div>

    <span class="br-divider d-none d-sm-block mx-3" aria-hidden="true"></span>
    <div class="pagination-arrows">
      <button
        class="br-button is-tertiary circle"
        [disabled]="paginatedResult.first"
        (click)="previousPage()"
        aria-label="Voltar página"
      >
        <fa-icon [icon]="leftArrowIcon" aria-hidden="true"></fa-icon>
      </button>

      <button
        class="br-button is-tertiary circle"
        [disabled]="paginatedResult.last"
        (click)="nextPage()"
        aria-label="Avançar página"
      >
        <fa-icon [icon]="rightArrowIcon" aria-hidden="true"></fa-icon>
      </button>
    </div>
  </div>
    
</nav>
