import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavBarService {
  private currStatus: boolean = true;
  private isHidden = new Subject<boolean>();
  isHiddenObs = this.isHidden.asObservable();

  constructor() {
    this.isHidden.next(true);

    this.isHidden.subscribe((newStatus) => {
      this.currStatus = newStatus;
    });
  }

  toggleIsHidden() {
    this.isHidden.next(!this.currStatus);
  }

  setIsHidden(status: boolean) {
    this.isHidden.next(status);
  }
}
