import { Component } from '@angular/core';
import {
  faBookOpenReader,
  faChartPie,
  faClipboardList
} from '@fortawesome/free-solid-svg-icons';
import { NavBarService } from '../../services/nav-bar.service';

@Component({
  selector: 'app-nav-bar-manager',
  templateUrl: './nav-bar-manager.component.html',
  styleUrls: ['./nav-bar-manager.component.scss']
})
export class NavBarManagerComponent {
  faChartPie = faChartPie;
  faBookOpenReader = faBookOpenReader;
  faClipboardList = faClipboardList;

  constructor(private navBarService: NavBarService) {}

  hideMenu() {
    this.navBarService.setIsHidden(true);
  }
}
