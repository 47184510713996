<nav *ngIf="!hidden" aria-label="breadcrumb" class="mt-2">
  <ol class="breadcrumb">
    <li *ngFor="let breadcrumb of breadcrumbs" class="breadcrumb-item">
      <ng-container
        *ngTemplateOutlet="
          breadcrumbs.at(-1)?.url === breadcrumb.url ? activeRoute : breadcrumbRoute;
          context: { $implicit: breadcrumb, breadcrumb: breadcrumb }
        "
      ></ng-container>
    </li>
  </ol>
</nav>

<ng-template #breadcrumbRoute let-breadcrumb="breadcrumb">
  <a [routerLink]="breadcrumb.url" class="fw-bold text-primary">{{
    breadcrumb.label.toUpperCase()
  }}</a>
</ng-template>

<ng-template #activeRoute let-breadcrumb="breadcrumb">
  <span class="m-0 fw-normal text-neutral-dark">
    {{ breadcrumb.label.toUpperCase() }}
  </span>
</ng-template>
