<div class="page-header">
    <h1 class="page-title">Configurações</h1>
</div>
<div class="br-tab mb-4">
    <nav class="tab-nav">
        <ul>
            <li [class.active]="activeTab === 'dados'" class="tab-item">
                <button (click)="onTabClick('dados')">Dados do usuário</button>
            </li>
            <li [class.active]="activeTab === 'perfis'" class="tab-item">
                <button (click)="onTabClick('perfis')">Perfis de acesso</button>
            </li>
        </ul>
    </nav>
</div>

<div *ngIf="activeTab === 'dados'">
    <app-dados (emitUser)="getUser($event)" [user]="user"></app-dados>
</div>

<div *ngIf="activeTab === 'perfis'">
    <app-perfis [user]="user" [perfis]="perfis" [usuario]="usuario"></app-perfis>
    <!-- <app-perfis [user]="user" [perfis]="perfis" [usuario]="usuario"></app-perfis> -->
</div>