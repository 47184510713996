<div class="d-flex flex-column min-vh-100">
  <app-header />

  <div class="container-fluid main-container">
    <div class="row flex-grow-1 main-panel position-relative">
      <!-- <ng-container *ngIf="hidden">
        <app-nav-bar 
        id="nav-bar"
        class="col-12 col-md-auto col-lg-auto p-0"
        [ngClass]="{ 'd-none': isNavBarHidden }"
      />
      </ng-container> -->
      <ng-container *ngIf="!hidden">
        <app-nav-bar id="nav-bar" class="col-12 col-md-auto p-0" [ngClass]="{ 'd-none': isNavBarHidden }" />
      </ng-container>


      <main class="main col-12 col-md-auto">
        <!-- <app-breadcrumb /> -->
        <router-outlet></router-outlet>
        
      </main>
    </div>
  </div>


  <app-footer />
  <block-ui></block-ui>
</div>