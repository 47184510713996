
<div class="botoes">
    <button appDisableClick (click)="updateAluno()" 
        [disabled]="isValid" type="button" class="br-button is-primary is-small"> 
        Salvar
    </button>  
    <button  (click)="cancel()" class="br-button danger is-tertiary is-small">
        <fa-icon [icon]=faXmark aria-hidden="true"></fa-icon>Cancelar
    </button>
</div>
  
