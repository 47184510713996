import { SituacaoAluno } from '../types/typeFicha';
import { Contato } from './contato';
import { Expediente } from './expediente';
import { Visita } from './visita';

export class FichaListItem {
  id?: number;
  numero?: string;
  aluno?: string;
  createdOn?: string;
  updatedOn?: string;
  status?: string;
  localizacaoAtual?: string;
  urgente: boolean;
  situacaoDeRisco: boolean;

  constructor(
    id: number,
    numero: string,
    aluno: string,
    createdOn: string,
    updatedOn: string,
    status: string,
    localizacaoAtual: string,
    urgente: boolean,
    situacaoDeRisco: boolean
  ) {
    this.id = id;
    this.numero = numero;
    this.aluno = aluno;
    this.createdOn = createdOn;
    this.updatedOn = updatedOn;
    this.status = status;
    this.localizacaoAtual = localizacaoAtual;
    this.urgente = urgente;
    this.situacaoDeRisco = situacaoDeRisco;
  }
}

export class Ficha {
  situacaoAluno!: SituacaoAluno;
  status: string;
  alunoId: number;

  constructor(type: SituacaoAluno, status = 'ativa', alunoId: number) {
    this.situacaoAluno = type;
    this.status = status;
    this.alunoId = alunoId;
  }
}

export class FichaNewDTO {
  id?: string;
  escolaId?: number;
  alunoId?: number;
  situacaoAluno?: number;
  status?: string;
  anoCurso?: string;
  turno?: string;
  turma?: string;
  inicioFaltas?: string;
  professorReferencia?: string;
  observacoes?: string;
  informacoesComplementares?: string;
  escolaNome?: string;
  tempoAfastamento?: string;
  municipioNome?: string;
  estadoProvinciaDepartamento?: string;
  municipioId?: number;
  paisId?: number;
  diagnosticos?: Array<any>;
  localizacaoAtual?: string;
  providencias?: string;
  expedientesExtrajudiciais?: Array<Expediente>;
  municipioAberturaId?: number;
  contatos?: Array<Contato>;
  visitas?: Array<Visita>;
  raeId?: number;
  dataRetorno?: string;
  observacoesRetorno?: string;

  constructor(
    situacaoAluno: number,
    // status: string,
    alunoId: number,
    escolaId: number,
    anoCurso: string,
    turno: string,
    turma: string,
    inicioFaltas: string,
    professorReferencia: string,
    observacoes: string,
    informacoesComplementares: string,
    escolaNome: string,
    tempoAfastamento: string,
    municipioNome: string,
    estadoProvinciaDepartamento: string,
    municipioId: number,
    paisId: number,
    diagnosticos: Array<any>,
    expedientesExtrajudiciais: Array<Expediente>,
    raeId?: number,
    dataRetorno?: string,
    observacoesRetorno?: string
  ) {
    this.situacaoAluno = situacaoAluno;
    // this.status = status;
    this.alunoId = alunoId;
    this.escolaId = escolaId;
    this.escolaNome = escolaNome;
    this.anoCurso = anoCurso;
    this.turno = turno;
    this.turma = turma;
    this.inicioFaltas = inicioFaltas;
    this.professorReferencia = professorReferencia;
    this.observacoes = observacoes;
    this.informacoesComplementares = informacoesComplementares;
    this.tempoAfastamento = tempoAfastamento;
    this.municipioNome = municipioNome;
    this.estadoProvinciaDepartamento = estadoProvinciaDepartamento;
    this.municipioId = municipioId;
    this.paisId = paisId;
    this.diagnosticos = diagnosticos;
    this.expedientesExtrajudiciais = expedientesExtrajudiciais;
    this.raeId = raeId;
    this.dataRetorno = dataRetorno;
    this.observacoesRetorno = observacoesRetorno;
  }
}

export interface FichaT {
  id?: number;
  escolaId?: number;
  alunoId?: number;
  situacaoAluno?: number;
  status?: string;
  anoCurso?: string;
  turno?: string;
  turma?: string;
  inicioFaltas?: string;
  professorReferencia?: string;
  observacoes?: string;
  informacoesComplementares?: string;
  escolaNome?: string;
  tempoAfastamento?: string;
  municipioNome?: string;
  estadoProvinciaDepartamento?: string;
  municipioId?: number;
  paisId?: number;
  diagnosticos?: Array<any>;
  localizacaoAtual?: string;
  expedientesExtrajudiciais?: Array<Expediente>;
}
