<div class="row justify-content-center align-items-center" style="min-height: 80vh;">
    <div class="col-xl-10">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="page-header">
                    <h1 class="page-title">Página não <span style="text-wrap: nowrap;">encontrada :(</span></h1>
                </div>
                <p class="lead">Se você acredita que se trata de um problema no sistema, mande um e-mail para <a
                        href="mailto:ficai4.0@procempa.com.br">ficai4.0@procempa.com.br</a> e explique o que aconteceu.
                </p>
                <p class="lead">Se você acha que se enganou,
                    <a click="back" class="back" (click)="voltar()">volte para a página anterior</a>.
                </p>
                <!-- TODO: adicionar link para voltar à página anterior -->
            </div>
            <div class="col-lg-6">
                <img class="img-responsive" src="../assets/images/sem-resultados.png" 
                alt="Ilustração de estudante cercado por documentos e pontos de interrogação, 
                coçando a cabeça em pose de dúvida">
            </div>
        </div>
    </div>
</div>