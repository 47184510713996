import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'avatar'
})
export class AvatarPipe implements PipeTransform {
  transform(nome: string): string {
    let nomeNew = nome.split(' ');
    let qt = nomeNew.length;

    let firstName = nomeNew[0];
    let lastName = nomeNew[qt - 1];

    return firstName.substring(0, 1).concat(lastName.substring(0, 1));
  }
}
