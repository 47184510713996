  <div class="page-header avatar-container">
    <div class="avatar" *ngIf="usuario !== undefined">
        <span>{{usuario.nome! | avatar}}</span>
    </div>
    <div>
        <span class="h4">{{usuario?.nome}}</span>
        <p class="texto-secundario">{{ usuario?.email}}</p>
    </div>
</div>

<p *ngIf="!!coreService.user.emailPreferencial">
    <strong class="texto-secundario">E-mail preferencial</strong><br>
    {{coreService.user.emailPreferencial}} 
</p>

<p *ngIf="!!usuario?.emailContato">
    <strong class="texto-secundario">E-mail preferencial</strong><br>
    {{usuario?.emailContato}} 
</p>