<header class="d-flex align-items-center justify-content-between" [ngClass]="{'header-color': autorizador}">
    <div class="d-flex gap-3">
        <div class="d-flex d-md-none" *ngIf="isLogado">
            <button class="br-button is-tertiary p-0" (click)="toggleMenu()">
                <fa-icon class="fs-1" [icon]="isNavBarHidden ? menuIcon : closeMenuIcon"
                    aria-label="Expandir/recolher menu"></fa-icon>
            </button>
        </div>

        <a  (click)="setMenuHidden(true)" routerLinkActive="router-link-active">
            <img src="/assets/images/ficai.svg" alt="ficai">
        </a>
        <span *ngIf="autorizador" class="autorizador">Autorizador</span>
    </div>

    <ng-container>
        <div class="usuario">
            <div class="dados-perfil d-none d-md-block">
                <span [ngStyle]="{color: autorizador ? '#fff' : ''}" >{{user?.nome}}</span>
                <small class="texto-secundario">
                    <strong 
                        *ngIf="urlAtiva !== '/perfis' && urlAtiva !== '/autorizador/perfis'"
                        [ngStyle]="{color: autorizador ? '#fff' : ''}"
                        >{{perfilAtivo}}
                    </strong>
                </small>
            </div>
            <div class="avatar" (click)="perfilToggle()" *ngIf="user !== undefined">
                <p class="fw-bold align-self-center m-0 avatar">{{user.nome! | avatar}}</p>
            </div>
            <nav class="menu" aria-label="Menu do usuário">
                <div class="dados-perfil d-md-none">
                    <span>{{user?.nome}}</span>
                    <small class="texto-secundario">
                        <strong>{{perfilAtivo}}</strong>
                    </small>
                </div>
                <p class="texto-secundario mb-2" id="titulo-menu-perfil">Trocar perfil</p>
                <ul aria-describedby="titulo-menu-perfil" class="submenu" *ngIf="user?.aceitaTermosDeUso">
                    <li class="label" *ngIf="user?.perfisFichas?.length !== 0">Fichas e Relatórios</li> 
                    <li *ngFor="let perfil of user?.perfisFichas">
                        <a (click)="setPerfilUser(perfil.id,'/fichas')" 
                            *ngIf="isPerfilAutorizado(perfil); else elseBlock">
                            {{getPerfis(perfil.perfil)}}
                        </a>
                        <ng-template #elseBlock><span (click)="perfilToggle()" 
                            [ngClass]="{'activePerfil': !perfil.ativo}" >
                            {{getPerfis(perfil.perfil)}}</span>
                        </ng-template>

                    </li>
                    <li class="label" *ngIf="user?.perfisRelatorios?.length !== 0">Relatórios</li> 
                    <li *ngFor="let perfil of user?.perfisRelatorios">
                        <a (click)="setPerfilUser(perfil.id,'/relatorios/relatorio')"
                        *ngIf="isPerfilAutorizado(perfil); else elseBlock">
                            {{getPerfis(perfil.perfil)}}
                        </a>
                        <ng-template #elseBlock><span (click)="perfilToggle()" 
                            [ngClass]="{'activePerfil': !perfil.ativo}" >
                            {{getPerfis(perfil.perfil)}}</span>
                        </ng-template>
                    </li>
                    <li class="label" *ngIf="user?.perfisAutorizacao?.length !== 0">Autorizador</li> 
                    <li *ngFor="let perfil of user?.perfisAutorizacao">
                        <a (click)="setPerfilUser(perfil.id,'/autorizador/usuarios')" 
                            *ngIf="isPerfilAutorizado(perfil); else elseBlock">
                            {{getPerfis(perfil.perfil)}}
                        </a>
                        <ng-template #elseBlock><span (click)="perfilToggle()" 
                            [ngClass]="{'activePerfil': !perfil.ativo}" >
                            {{getPerfis(perfil.perfil)}}</span>
                        </ng-template>
                    </li>
                    <li class="label" *ngIf="user?.perfisGestao?.length !== 0">Gestor</li> 
                    <li *ngFor="let perfil of user?.perfisGestao">
                        <a (click)="setPerfilUser(perfil.id,'/gerenciamento/list-escolas')"
                            *ngIf="isPerfilAutorizado(perfil); else elseBlock"
                        >
                            {{getPerfis(perfil.perfil)}}
                        </a>
                        <ng-template #elseBlock><span (click)="perfilToggle()" 
                            [ngClass]="{'activePerfil': !perfil.ativo}" >
                            {{getPerfis(perfil.perfil)}}</span>
                        </ng-template>
                    </li>
                </ul>          
              
                <a (click)="config()" (click)="perfilToggle()" *ngIf="user?.aceitaTermosDeUso">
                    Configurações
                </a><br>
                <a (click)="logout()" class="mt-3">
                    <strong>Sair <fa-icon [icon]="signOut" aria-hidden="true"></fa-icon>
                    </strong>
                </a>
            </nav>
        </div>
    </ng-container>
</header>