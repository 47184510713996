import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonEditFormComponent } from './components/button-edit-form/button-edit-form.component';
import { ErrorMsgComponent } from './components/error-msg/error-msg.component';
import { ListaTabelaComponent } from './components/lista-tabela/lista-tabela.component';
import { LiveSearchInputComponent } from './components/live-search-input/live-search-input.component';
import { PaginacaoComponent } from './components/paginacao/paginacao.component';
import { CpfFormatterPipe } from './pipes/cpf-formatter.pipe';
import { PhoneFormatterPipe } from './pipes/phone-formatter.pipe';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ProcedimentoFormattedPipe } from './pipes/procedimento-formatted.pipe';
import { JudicialFormattedPipe } from './pipes/judicial-formatted.pipe';
import { AvatarPipe } from './pipes/avatar.pipe';
import { DisableAfterClickDirective } from './diretivas/disable-after-click.directive';
import { TableGenericComponent } from './components/table-generic/table-generic.component';
import { TooltopDirective } from './diretivas/tooltop.directive';

@NgModule({
  declarations: [
    LiveSearchInputComponent,
    ErrorMsgComponent,
    ButtonEditFormComponent,
    CpfFormatterPipe,
    PaginacaoComponent,
    ListaTabelaComponent,
    PhoneFormatterPipe,
    PageNotFoundComponent,
    ProcedimentoFormattedPipe,
    JudicialFormattedPipe,
    AvatarPipe,
    DisableAfterClickDirective,
    TableGenericComponent,
    TooltopDirective
  ],
  imports: [CommonModule, FontAwesomeModule, FormsModule, ReactiveFormsModule],
  exports: [
    LiveSearchInputComponent,
    ErrorMsgComponent,
    ButtonEditFormComponent,
    CpfFormatterPipe,
    PaginacaoComponent,
    ListaTabelaComponent,
    PhoneFormatterPipe,
    ProcedimentoFormattedPipe,
    JudicialFormattedPipe,
    AvatarPipe,
    DisableAfterClickDirective,
    TableGenericComponent,
    TooltopDirective
  ]
})
export class SharedModule {}
