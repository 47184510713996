import { ElementRef, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, fromEvent, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandlerErrorService {
  el?: ElementRef;
  constructor(private toastr: ToastrService) {}

  showSuccess() {
    this.toastr.success('Operação realizada com sucesso', '', {
      timeOut: 3000
    });
  }

  onError(err: any) {
    this.toastr.error('Não foi possível concluir', `${err.error.status}`, {
      timeOut: 3000
    });
  }

  FieldError() {
    this.toastr.error(
      'Verifique se todos os campos estão preenchidos corretamente',
      '',
      {
        timeOut: 10000,
        progressBar: true
      }
    );
    this.scrollToFirstInvalidControl();
  }

  forbiiden() {
    this.toastr.error('Permissão negada', ``, {
      timeOut: 3000
    });
  }

  permissaoNegada() {
    this.toastr.error('Permissão negada', ``, {
      timeOut: 3000
    });
  }

  sessionExpired(err: any) {
    this.toastr.error('Sessão expirada', `${err.error.status}`, {
      timeOut: 3000
    });
  }

  badRequest(err: any) {
    this.toastr.error('', `${err}`, {
      timeOut: 10000,
      progressBar: true
    });
  }

  errorSpecific(msg: string) {
    this.toastr.error(msg, '', {
      timeOut: 3000,
      positionClass: 'toast-top-right'
    });
  }

  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el?.nativeElement.querySelector('form .ng-invalid');

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth'
    });

    fromEvent(window, 'scroll')
      .pipe(debounceTime(100), take(1))
      .subscribe(() => firstInvalidControl?.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return (
      controlEl?.getBoundingClientRect().top + window.scrollY - labelOffset
    );
  }

  hasError(form: FormGroup, data: string): boolean {
    return (
      form.controls[data].invalid &&
      (form.controls[data].dirty || form.controls[data].touched)
    );
  }
}
