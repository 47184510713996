import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'judicialFormatted'
})
export class JudicialFormattedPipe implements PipeTransform {
  transform(value: string): unknown {
    return `${value.slice(0, 7)}-${value.slice(7, 9)}.${value.slice(
      9,
      13
    )}.${value.slice(13)}.${value.slice(13, 15)}.${value.slice(15, 19)}`;
  }
}
