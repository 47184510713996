<ng-content select="[filters]"></ng-content>

<div class="row">
  <div class="table-responsive">
    <table class="table mb-0">
          <thead >
        <!-- <tr *ngIf="headersLocal.length !== 0">
          <th scope="col" colspan="1"></th>
          <th *ngFor="let local of headersLocal" scope="col" colspan="2">{{local.descricao}}</th>
        </tr> -->
      </thead>
      <thead>
        <tr>
          <th *ngFor="let header of headers">
            {{ header.descricao }}
            <!-- <fa-icon [icon]="sortIcons.default" *ngIf="
                  paginatedResult.query.atributo !== header.atributo &&
                  header.ordenavel
                " (click)="changeOrder(header)" />
            <fa-icon [icon]="sortIcons.asc" *ngIf="
                  paginatedResult.query.atributo === header.atributo &&
                  paginatedResult.query.direction === 'ASC' &&
                  header.ordenavel
                " (click)="changeOrder(header)" />
            <fa-icon [icon]="sortIcons.desc" *ngIf="
                  paginatedResult.query.atributo === header.atributo &&
                  paginatedResult.query.direction === 'DESC' &&
                  header.ordenavel
                " (click)="changeOrder(header)" /> -->
          </th>
        </tr>
      </thead>
      <tbody>
       <ng-container *ngIf="!loading; else loadingTemplate">       
          <ng-content select="[table]" *ngIf="paginatedResult.length !== 0; else empty" />
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<ng-template #empty>
  <td colspan="5">
    <div class="sem-resultados">
      <p class="lead">Nenhum resultado encontrado!</p>
      <img class="img-responsive" src="../assets/images/sem-resultados.png" 
            alt="Ilustração de estudante cercado por documentos e pontos de interrogação, 
        coçando a cabeça em pose de dúvida">
      <p>Refaça a busca ou cadastre um novo estudante.</p>
    </div>
  </td>
</ng-template>

<ng-template #loadingTemplate>
  <td colspan="5">
    <div class="d-flex align-items-center justify-content-center">
      <div>
        <span class="visually-hidden">Carregando</span>
      </div>
    </div>
  </td>
</ng-template>
