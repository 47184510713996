import { Component, OnInit } from '@angular/core';
import { faBars, faXmark, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { NavBarService } from '../../services/nav-bar.service';
import { PerfilGeneric } from '../../model/perfilGeneric';
import { CoreService } from '../../services/core.service';
import { FichaService } from 'src/app/fichas/services/ficha.service';
import { KeycloakService } from 'keycloak-angular';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { UsuarioDto } from '../../model/dtos/usuarioDto';
// import { PerfisService } from 'src/app/perfis/perfis.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  readonly menuIcon = faBars;
  readonly closeMenuIcon = faXmark;
  readonly signOut = faSignOut;

  isNavBarHidden: boolean = true;
  isPerfisHidden: boolean = true;

  user?: UsuarioDto;
  perfilAtivo?: string;
  isLogado?: boolean = false;
  urlAtiva?: string;
  autorizador = false;

  constructor(
    private navBarService: NavBarService,
    public service: CoreService,
    private fichaService: FichaService,
    private keycloak: KeycloakService,
    private router: Router // private perfisService: PerfisService
  ) {
    navBarService.isHiddenObs.subscribe((isHidden) => {
      this.isNavBarHidden = isHidden;
    });
  }

  async ngOnInit() {
    this.service.getUsuarioBehavior().subscribe((value) => {
      this.user = value;
    });
    this.isLogado = await this.keycloak.isLoggedIn();

    if (this.isLogado) {
      this.service.changePerfilAtivo.subscribe(
        (response: PerfilGeneric | undefined) => {
          if (response?.perfil == 'MINISTERIO_PUBLICO') {
            this.perfilAtivo = 'Ministério Público';
          } else if (response?.perfil == 'CONSELHO_TUTELAR') {
            this.perfilAtivo = 'Conselho Tutelar';
          } else if (response?.perfil == 'ESCOLA') {
            this.perfilAtivo = 'Escola';
          } else if (response?.perfil == 'RAE') {
            this.perfilAtivo = 'Rede de Apoio Escolar';
          } else if (response?.perfil == 'AUTORIZADOR_MUN') {
            this.perfilAtivo = 'Autorizador Municipal';
          } else if (response?.perfil == 'AUTORIZADOR_EST') {
            this.perfilAtivo = 'Autorizador Seduc';
          } else if (response?.perfil == 'AUTORIZADOR_MP') {
            this.perfilAtivo = 'Autorizador MP';
          } else if (response?.perfil == 'AUTORIZADOR_SUPER') {
            this.perfilAtivo = 'Autorizador Super';
          } else if (response?.perfil == 'AUTORIZADOR_IF') {
            this.perfilAtivo = 'Autorizador Federal';
          } else if (response?.perfil == 'CRE') {
            this.perfilAtivo = 'Coordenadoria Regional de Educação';
          } else if (response?.perfil == 'SME') {
            this.perfilAtivo = 'Secretaria Municipal de Educação';
          } else if (response?.perfil == 'SE_RELATORIO') {
            this.perfilAtivo = 'Secretaria Estadual de Educação';
          } else if (response?.perfil == 'MP_RELATORIO') {
            this.perfilAtivo = 'Minísterio Público (Relatório)';
          } else if (response?.perfil == 'PROCEMPA_TIMP') {
            this.perfilAtivo = 'Procempa';
          }
        }
      );

      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd | any) => {
          this.urlAtiva = event.url;
          this.autorizador = event.url.startsWith('/autorizador');
        });
    }
  }

  config() {
    this.router.navigate(['config']);
  }

  toggleMenu() {
    this.navBarService.toggleIsHidden();
  }

  setMenuHidden(isHidden: boolean) {
    this.navBarService.setIsHidden(isHidden);
  }

  perfilToggle() {
    const perfil = document.querySelector('.menu');
    perfil?.classList.toggle('active');
  }

  logout() {
    this.service.clear();
    this.service.cancel();
  }

  isPerfilAutorizado(perfil: PerfilGeneric): boolean {
    return perfil.locais?.some((l) => l.status === 'AUTORIZADO') ? true : false;
  }

  getPerfis(perfil: string) {
    if (perfil == 'MINISTERIO_PUBLICO') {
      return 'Ministério Público';
    }

    if (perfil == 'CONSELHO_TUTELAR') {
      return 'Conselho Tutelar';
    }

    if (perfil == 'ESCOLA') {
      return 'Escola';
    }

    if (perfil == 'RAE') {
      return 'Rae';
    }

    if (perfil == 'AUTORIZADOR_MUN') {
      return 'Autorizador Municipal';
    }

    if (perfil == 'AUTORIZADOR_EST') {
      return 'Autorizador Seduc';
    }

    if (perfil == 'AUTORIZADOR_MP') {
      return 'Autorizador MP';
    }

    if (perfil == 'AUTORIZADOR_IF') {
      return 'Autorizador Federal';
    }

    if (perfil == 'AUTORIZADOR_SUPER') {
      return 'Autorizador Super';
    }

    if (perfil == 'CRE') {
      return 'Cre';
    }

    if (perfil == 'SME') {
      return 'Secretaria Municipal de Educação';
    }

    if (perfil == 'SE_RELATORIO') {
      return 'Secretaria Estadual de Educação';
    }

    if (perfil == 'MP_RELATORIO') {
      return 'Ministério Público (Relatórios)';
    }

    if (perfil == 'PROCEMPA_TIMP') {
      return 'Procempa';
    }

    if (perfil == 'GESTOR_MP') {
      return 'Gestor Ministério Público';
    }

    if (perfil == 'GESTOR_MUN') {
      return 'Gestor Municipal';
    }

    if (perfil == 'GESTOR_MP') {
      return 'Gestor Ministério Público';
    }

    if (perfil == 'GESTOR_EST') {
      return 'Gestor Estadual';
    }

    return '';
  }

  setPerfilUser(id: any, perfil: string) {
    this.service.putPerfil(id).subscribe((response: boolean) => {
      if (response === true) {
        this.router.navigate([`${perfil}`]).then(() => {
          window.location.reload();
        });
        this.service.getUsuarioActive().subscribe((perf: PerfilGeneric) => {
          this.perfilAtivo = perf.perfil;
          this.fichaService.setPerfil(perf.perfil);
          const perfil = document.querySelector('.menu');
          perfil?.classList.remove('active');
        });
      }
    });
  }

  removeMenu() {
    const perfil = document.querySelector('.menu');
    perfil?.classList.remove('active');
  }
}
