import { Component, Input } from '@angular/core';
import { CoreService } from '../../services/core.service';
import { UsuarioDto } from '../../model/dtos/usuarioDto';

@Component({
  selector: 'app-info-user',
  templateUrl: './info-user.component.html',
  styleUrls: ['./info-user.component.scss']
})
export class InfoUserComponent {
  @Input() usuario?: UsuarioDto;

  constructor(public coreService: CoreService) {}
}
