<div class="input-group" [ngClass]="icon ? 'has-icon' : ''">
  <input
    type="text"
    class="form-control"
    [id]="id"
    [placeholder]="placeholder"
    [value]="initialValue"
    (input)="onInput($event)"
  />

  <button *ngIf="icon" class="br-button is-tertiary">
    <fa-icon [icon]="icon" aria-hidden="true"></fa-icon>
  </button>
</div>
