import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { HandlerErrorService } from '../shared/services/handler-error.service';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {
  constructor(
    private errorMsg: HandlerErrorService,
    private router: Router,
    private kc: KeycloakService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.errorMsg.forbiiden();
        } else if (error.status === 401) {
          // this.errorMsg.sessionExpired(error);
          this.kc.logout();
        } else if (error.status === 400) {
          // this.errorMsg.badRequest(
          //   error.error.cpf || error.error.situacaoAluno
          // );
          this.errorMsg.scrollToFirstInvalidControl();
        } else if (error.status === 404) {
          this.router.navigate(['/not-found']);
        }
        return throwError(error);
      })
    );
  }
}
