import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../services/core.service';
import { PerfilGeneric, Usuario } from '../../model/perfilGeneric';
import { UsuarioDto } from '../../model/dtos/usuarioDto';

@Component({
  selector: 'app-configuracao',
  templateUrl: './configuracao.component.html',
  styleUrls: ['./configuracao.component.scss']
})
export class ConfiguracaoComponent implements OnInit {
  activeTab: string = 'dados';
  user?: Usuario;
  usuario!: UsuarioDto;
  perfis?: Array<PerfilGeneric>;
  isLogado?: boolean = false;

  constructor(public coreService: CoreService) {}

  async ngOnInit() {
    this.coreService.getUsuario().subscribe((user: Usuario) => {
      this.user = user;
      this.perfis = this.user?.perfis;
    });

    this.coreService.getUsuarioBehavior().subscribe((value) => {
      this.usuario = value;
    });
  }

  onTabClick(tab: any) {
    this.activeTab = tab;
  }

  getUser(event: any) {
    this.user = event as Usuario;
  }
}
