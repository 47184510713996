<p>Estes são os seus dados de usuário obtidos do acesso pelo gov.br:</p>
<div class="data-view block">
    <p><strong class="texto-secundario">Nome completo</strong>{{user?.nome}} </p>
    <p *ngIf="user?.cpf !== null"><strong class="texto-secundario">CPF</strong>{{user?.cpf! | cpfFormatter}}</p>
    <p><strong class="texto-secundario">E-mail</strong>{{user?.email}}</p>
</div>

<div>
    <div class="section-header">
        <label class="form-label col-auto">E-mail preferencial</label>
        <button *ngIf="hidden" [hidden]="!hidden" (click)="edit()" class="br-button is-tertiary"><fa-icon
                [icon]=faPenToSquare aria-hidden="true">
            </fa-icon>Editar
        </button>

        <button *ngIf="!hidden" (click)="cancel()" class="br-button is-tertiary danger">
            <fa-icon [icon]="faXmark" aria-hidden="true"></fa-icon>
            Cancelar
        </button>

        <!-- <button *ngIf="!hidden" (click)="cancel()" class="br-button is-tertiary"><fa-icon
            [icon]=faPenToSquare aria-hidden="true">
        </fa-icon>Cancelar
    </button> -->
    </div>

    <p *ngIf="hidden">{{user?.emailContato}}</p>

    <form *ngIf="!hidden" [formGroup]="form">
        <div class="row">
            <div class="col-lg-8 col-xl-6 form-group">
                <div class="input-group">
                    <input  type="text" class="form-control" formControlName="email">
                    <button (click)="save()" class="br-button is-primary" [disabled]="form.valid" type="button"
                        [disabled]="!form.valid">Salvar</button>
                </div>
            </div>
        </div>
    </form>
    <small class="helper">Este e-mail é utilizado para envio de alertas e mensagens do sistema FICAI</small>
</div>

