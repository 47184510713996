import { Component, OnInit } from '@angular/core';
import {
  faChartPie,
  faBookOpenReader,
  faClipboardList
} from '@fortawesome/free-solid-svg-icons';
import { NavBarService } from '../../services/nav-bar.service';
import { CoreService } from '../../services/core.service';
import { FichaService } from 'src/app/fichas/services/ficha.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  faChartPie = faChartPie;
  faBookOpenReader = faBookOpenReader;
  faClipboardList = faClipboardList;
  relatorios = true;
  perfil!: string;

  constructor(
    private navBarService: NavBarService,
    private core: CoreService,
    private ficha: FichaService
  ) {}

  ngOnInit(): void {
    this.ficha.getPerfil().subscribe((value) => {
      this.perfil = value;
    });

    this.core.getUsuarioActive().subscribe((value) => {
      this.perfil = value.perfil;
    });
  }

  hideMenu() {
    this.navBarService.setIsHidden(true);
  }
}
