import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { CoreService } from '../../services/core.service';
import { UsuarioDto } from '../../model/dtos/usuarioDto';
import { PerfilGeneric } from '../../model/perfilGeneric';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loading: boolean = true;
  perfis?: Array<PerfilGeneric>;
  teste: boolean = false;

  constructor(
    private router: Router,
    private keycloak: KeycloakService,
    private service: CoreService
  ) {}

  isLogado: boolean = false;
  async ngOnInit() {
    this.isLogado = await this.keycloak.isLoggedIn();

    if (!this.isLogado) {
      this.router.navigate(['/home']);
    }

    if (this.isLogado) {
      this.service.getUsuarioBehavior().subscribe((value: UsuarioDto) => {
        if (value !== undefined) {
          if (value.aceitaTermosDeUso) {
            this.router.navigate(['perfis-list']);
          } else {
            this.router.navigate(['perfis-list/termo-uso']);
          }
        }
      });
    }
  }

  login() {
    this.keycloak.login();
  }
}
