import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'procedimentoFormatted'
})
export class ProcedimentoFormattedPipe implements PipeTransform {
  transform(value: string): unknown {
    return `${value.slice(0, 5)}.${value.slice(5, 8)}.${value.slice(
      8,
      11
    )}/${value.slice(11, 15)}`;
  }
}
