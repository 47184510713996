import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  take,
  throwError
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { PromotoriaDto } from '../model/dtos/promotoriaDto';
import { PerfilDto } from '../model/dtos/perfilDto';
import { UserDados, UsuarioNewDto } from '../model/dtos/usuarioNewDto';
import { PerfilGeneric, Usuario } from '../model/perfilGeneric';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import { HandlerErrorService } from 'src/app/shared/services/handler-error.service';
import Swal from 'sweetalert2';
import { UsuarioDto } from '../model/dtos/usuarioDto';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  usuario = new BehaviorSubject<UsuarioDto | any>(undefined);
  logado: boolean = false;
  hidden = new BehaviorSubject(false);
  perfis: Array<PerfilDto> = [];
  user: UserDados = new UserDados();

  //perfil = new BehaviorSubject(undefined);

  private _userActive = new BehaviorSubject<Usuario | any>(undefined);
  public userActive = this._userActive.asObservable();

  private perfilAtivo?: PerfilGeneric;

  private usuarioSource = new BehaviorSubject<Usuario | undefined>(undefined);
  public changeUsuario = this.usuarioSource.asObservable();

  private permissao = new BehaviorSubject<boolean | undefined>(undefined);
  public createAluno = this.permissao.asObservable();

  private perfilAtivoSource = new BehaviorSubject<PerfilGeneric | undefined>(
    undefined
  );
  public changePerfilAtivo = this.perfilAtivoSource.asObservable();

  private isAutorizadorPageSource = new BehaviorSubject<boolean>(false);
  public changeIsAutorizadorPage = this.isAutorizadorPageSource.asObservable();

  // // logado:
  backendUrl = environment.BASE_URL;
  //backendUrl = 'https://apigateway-hom.procempa.com.br/apiman-gateway/ambiental-urbanismo/ficai/1.0'

  constructor(
    private http: HttpClient,
    private router: Router,
    private kc: KeycloakService,
    private toastr: HandlerErrorService
  ) {}

  setUsuario(user: UsuarioDto): void {
    this.usuario.next(user);
  }

  getUsuarioBehavior(): Observable<UsuarioDto> {
    return this.usuario.asObservable();
  }

  getData(key: string): any {
    let obj = localStorage.getItem(key);
    return JSON.parse(obj || '');
  }

  setData(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getPayload(key: string): any {
    let obj = localStorage.getItem(key);
    return JSON.parse(obj || '');
  }

  setPayload(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  clear() {
    localStorage.clear();
    localStorage.removeItem('perfil');
    localStorage.removeItem('termos');
  }

  removeStorage(item: string) {
    localStorage.removeItem(item);
  }

  setUserActive(obj: any) {
    this._userActive.next(obj);
  }

  setHidden(msg: any): void {
    this.hidden.next(msg);
  }

  getHidden(): Observable<any> {
    return this.hidden.asObservable();
  }

  public getUsuario(): Observable<any> {
    let url = `${this.backendUrl}/usuario${
      this.isAutorizadorPage() ? '/autorizador' : ''
    }`;
    return this.http.get<any>(url);
  }

  public getUsuarioDto(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/usuario`);
  }

  public getAutorizador(): Observable<any> {
    let url = `${this.backendUrl}/usuario/autorizador`;
    return this.http.get<any>(url);
  }

  public getUsuarioActive(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/usuario/active-perfil`);
  }

  public permissaoCreateAluno(): any {
    this.http
      .get<any>(`${this.backendUrl}/usuario/active-perfil`)
      .subscribe((perfil: PerfilGeneric) => {
        if (perfil.perfil == 'ESCOLA' || perfil.perfil == 'RAE') {
          this.permissao.next(true);
        } else {
          this.permissao.next(false);
        }
      });
  }

  public putPerfil(id: number): Observable<any> {
    return this.http.put<any>(
      `${this.backendUrl}/usuario/active-perfil/${id}`,
      id
    );
  }

  public getMicros(id: number): Observable<any> {
    return this.http.get<any>(
      `${this.backendUrl}/conselho-tutelar/municipio/${id}`
    );
  }

  public getRae(id: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/rae/municipio/${id}`);
  }

  public getAllRae(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/rae`);
  }

  public getPromotorias(): Observable<Array<PromotoriaDto>> {
    return this.http.get<Array<PromotoriaDto>>(
      `${this.backendUrl}/promotoria/all`
    );
  }

  public create(usuario: UsuarioDto | UsuarioNewDto): Observable<any> {
    const url = `${this.backendUrl}/usuario`;
    return this.http.post<any>(url, usuario).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.toastr.badRequest(err.error.message);
          this.toastr.scrollToFirstInvalidControl();
        }
        return throwError(() => err);
      })
    );
  }

  public loadUsuario(): void {
    this.getUsuario().subscribe((u: Usuario) => {
      this.usuarioSource.next(u);
      let pa = u.perfis?.filter((p) => p.ativo === true)[0];
      if (pa !== undefined) {
        this.perfilAtivo = pa;
        this.perfilAtivoSource.next(pa);
      } else {
        this.perfilAtivo = undefined;
        this.perfilAtivoSource.next(undefined);
      }
    });
  }

  public setPerfilAtivo(id: number): void {
    this.putPerfil(id).subscribe((response: boolean) => {
      if (response) {
        //this.router.navigate(['/autorizador/home']);
        this.router.navigate(['/autorizador/usuarios']);
        //this.router.navigate(['/autorizador/home']);
        this.loadUsuario();
      }
    });
  }

  public setPerfilFicha(id: number): void {
    this.putPerfil(id).subscribe((response: boolean) => {
      if (response) {
        // if(perfil == 'escola'){
        //   // Swal.fire("SweetAlert2 is working!");
        //   this.getAlert()
        // }

        this.router.navigate(['/fichas']);
        //this.loadUsuario();
      }
    });
  }

  public setPerfilCre(id: number): void {
    this.putPerfil(id).subscribe((response: boolean) => {
      if (response) {
        this.router.navigate(['/relatorios']);
        //this.loadUsuario();
      }
    });
  }

  private isAutorizadorPage(): boolean {
    let isAutorizadorPage = this.router.url.startsWith('/autorizador');
    this.isAutorizadorPageSource.next(isAutorizadorPage);
    return isAutorizadorPage;
  }

  public getPerfilAtivo(): PerfilGeneric | undefined {
    return this.perfilAtivo;
  }

  public updatePerfisGeneric(
    id: number,
    event: any,
    router: string,
    action: string
  ) {
    let url = '';
    if (!event.target.checked) {
      url = `${router}/${id}/${action}`;
    } else {
      url = `${router}/${id}/${action}`;
    }

    return this.http.put<any>(`${this.backendUrl}/${url}`, id);
  }

  public getAutorizacoesMunicipioPendentes(): Observable<any> {
    return this.http.get<Array<any>>(
      `${this.backendUrl}/perfil-autorizador-mun/autorizacoes/pendentes`
    );
  }

  public getAutorizacoesEstadoPendentes(): Observable<any> {
    return this.http.get<Array<any>>(
      `${this.backendUrl}/perfil-autorizador-est/autorizacoes/pendentes`
    );
  }

  public getAutorizacoesMinisterioPendentes(): Observable<any> {
    return this.http.get<Array<any>>(
      `${this.backendUrl}/perfil-autorizador-mp/autorizacoes/pendentes`
    );
  }

  public getAutorizacoesSuperPendentes(): Observable<any> {
    return this.http.get<Array<any>>(
      `${this.backendUrl}/perfil-autorizador-super/autorizacoes/pendentes`
    );
  }

  public getUsuarioAutorizador(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/usuario/autorizador`);
  }

  perfilAtivoAutorizador(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/usuario/active-perfil`);
  }

  validatorForm(form: FormGroup) {
    Object.keys(form.controls).forEach((field: any) => {
      const control = form?.get(field);
      control?.markAsTouched();
    });
  }

  getMunicipio(id: number) {
    return this.http.get<any>(`${this.backendUrl}/municipio/${id}`);
  }

  cancel() {
    this.kc.logout(`${window.origin}/home`);
  }

  updateEmail(email: string): Observable<any> {
    return this.http.put<any>(`${this.backendUrl}/usuario/email`, email);
  }

  validaTermos(usuarioNew: UsuarioNewDto) {
    if (
      usuarioNew.aceitaTermosDeUso == undefined ||
      usuarioNew.aceitaTermosDeUso == false
    ) {
      if (
        this.user.aceitaTermosDeUso == undefined ||
        this.user.aceitaTermosDeUso == false
      ) {
        this.clear();
        this.router.navigate(['/auto-cadastro/termo-uso']);
      }
    }
  }

  validaTermosAutorizador(usuarioNew: UsuarioNewDto) {
    if (
      usuarioNew.aceitaTermosDeUso == undefined ||
      usuarioNew.aceitaTermosDeUso == false
    ) {
      if (
        this.user.aceitaTermosDeUso == undefined ||
        this.user.aceitaTermosDeUso == false
      ) {
        this.clear();
        this.router.navigate(['/autorizador/auto-cadastro/termo-uso']);
      }
    }
  }

  validaTermosT() {
    let teste = this.getUsuario().pipe(take(1)).toPromise();
    return teste;
  }

  getAllCres() {
    return this.http.get<any>(`${this.backendUrl}/cre/all`);
  }

  getAlert() {
    const teste = Swal.mixin({
      customClass: {
        title: 'styleTitle'
      }
    });

    teste.fire({
      title: 'Enchentes - situação da escola',
      // text: "Informe a situação da sua escola com relação às enchentes que atualmente afetam o RS:"
      html: `<p>Informe a situação da sua escola com relação às enchentes que atualmente afetam o RS:</p>`
    });
    // Swal.fire({
    //   title: "Enchentes - sitruação da escola",
    //   text: "Informe a situação da sua escola com relação às enchentes que atualmente afetam o RS:",
    //   customClass: "styleTitle"
    // });
  }

  getEnchente(idPerfil: number): Observable<any> {
    return this.http.get<any>(
      `${this.backendUrl}/escola/perfil/${idPerfil}/enchente`
    );
  }

  getEnchente1(idPerfil: number): Observable<any> {
    return this.http.get<any>(
      `${this.backendUrl}/escola/municipio/${idPerfil}`
    );
  }

  public postEnchente(situacao: any, id: number): Observable<any> {
    const url = `${this.backendUrl}/escola/${id}/enchente`;
    return this.http.post<any>(url, situacao).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.toastr.badRequest(err.error.message);
        }
        if (err.status === 200) {
          this.toastr.showSuccess();
        }
        return throwError(() => err);
      })
    );
  }
}
