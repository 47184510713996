import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomRoutes } from './shared/types/CustomRoutes';
import { HomeComponent } from './core/pages/home/home.component';
import { CanAuthenticationGuardService } from './guards/can-authentication-guard.service';
import { ConfiguracaoComponent } from './core/pages/configuracao/configuracao.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

const routes: CustomRoutes = [
  {
    path: 'not-found',
    component: PageNotFoundComponent,
    data: {
      breadcrumb: 'not-found'
    }
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      breadcrumb: 'Home',
      breadcrumbHidden: true
    }
  },
  {
    path: 'config',
    component: ConfiguracaoComponent,
    data: { breadcrumb: 'config' },
    canActivate: [CanAuthenticationGuardService]
  },
  {
    path: '',
    data: {
      breadcrumb: 'Início'
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/home' },
      {
        path: 'fichas',
        data: {
          breadcrumb: ''
        },
        loadChildren: () =>
          import('./fichas/fichas.module').then((m) => m.FichasModule),
        canActivate: [CanAuthenticationGuardService]
      },
      {
        path: 'alunos',
        data: {
          breadcrumb: ''
        },
        loadChildren: () =>
          import('./alunos/alunos.module').then((m) => m.AlunosModule),
        canActivate: [CanAuthenticationGuardService]
      },
      {
        path: 'relatorios',
        data: {
          breadcrumb: ''
        },
        loadChildren: () =>
          import('./relatorios/relatorios.module').then(
            (m) => m.RelatoriosModule
          ),
        canActivate: [CanAuthenticationGuardService]
      },
      {
        path: 'gerenciamento',
        data: {
          breadcrumb: ''
        },
        loadChildren: () =>
          import('./gerenciamento/gerenciamento.module').then(
            (m) => m.GerenciamentoModule
          ),
        canActivate: [CanAuthenticationGuardService]
      },
      {
        path: 'perfis-list',
        data: {
          breadcrumb: ''
        },
        loadChildren: () =>
          import('./perfis/perfis.module').then((m) => m.PerfisModule),
        canActivate: [CanAuthenticationGuardService]
      },
      {
        path: 'autorizador',
        data: {
          breadcrumb: ''
        },
        loadChildren: () =>
          import('./autorizacao/autorizacao.module').then(
            (m) => m.AutorizacaoModule
          ),
        canActivate: [CanAuthenticationGuardService]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), NgbModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
