import { Component, OnInit } from '@angular/core';
import { NavBarService } from './core/services/nav-bar.service';
import { KeycloakService } from 'keycloak-angular';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { CoreService } from './core/services/core.service';
import { UsuarioDto } from './core/model/dtos/usuarioDto';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ficai4-frontend';
  isNavBarHidden: boolean = true;
  isLogado: boolean = false;
  hidden: boolean = true;
  urlAtiva: string = '';

  constructor(
    navBarService: NavBarService,
    private keycloak: KeycloakService,
    private router: Router,
    private service: CoreService
  ) {
    navBarService.isHiddenObs.subscribe((isHidden) => {
      this.isNavBarHidden = isHidden;
    });
  }

  async ngOnInit() {
    this.isLogado = await this.keycloak.isLoggedIn();

    if (this.isLogado) {
      this.service.getUsuarioDto().subscribe((user: UsuarioDto) => {
        this.service.setUsuario(user);
      });
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd | any) => {
        if (this.isLogado) {
          this.service.loadUsuario();
          if (event.url.startsWith('/autorizador')) {
            this.hidden = true;
          } else if (
            event.url.startsWith('/perfis-list') ||
            event.url.startsWith('/home') ||
            event.url.startsWith('/auto-cadastro')
          ) {
            this.hidden = true;
          } else {
            this.hidden = false;
          }
        } else {
          this.hidden = true;
        }
      });
  }
}
