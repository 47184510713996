import { PerfilNewDto } from './perfilDto';

export interface UsuarioNewDto {
  id?: number;
  emailContato?: string;
  aceitaTermosDeUso?: boolean;
  aceitaTermosDadosPessoais?: boolean;
  perfis: Array<PerfilNewDto>;
}

// "id": 4,
// "status": true,
// "nome": "bruno teste",
// "cpf": "29475619020",
// "email": "bruno.annoni@procempa.com.br",
// "createdOn": null

export class UsuarioListItem {
  id?: number;
  status?: string;
  nome?: string;
  cpf?: string;
  email?: string;
  municipios?: string;
  createdOn?: string;

  constructor(
    id: number,
    status: string,
    nome: string,
    cpf: string,
    email: string,
    municipios: string,
    createdOn: string
  ) {
    this.id = id;
    this.status = status;
    this.nome = nome;
    this.cpf = cpf;
    this.email = email;
    this.municipios = municipios;
    this.createdOn = createdOn;
  }
}

export class UserDados {
  emailPreferencial?: string;
  aceitaTermosDeUso?: boolean;
  aceitaTermosDadosPessoais?: boolean;

  constructor() {}

  setTermoUso(termoUso: boolean) {
    this.aceitaTermosDeUso = termoUso;
  }

  setTermoDados(termoDados: boolean) {
    this.aceitaTermosDadosPessoais = termoDados;
  }

  setEmail(email: string) {
    this.emailPreferencial = email;
  }
}
