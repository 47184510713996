<div class="container" *ngIf="!isLogado; else loading">
    <div class="page-header">
        <h1 class="page-title">Bem-vindo à FICAI 4.0<br>
            <small>Ficha de Comunicação do Aluno Infrequente</small>
        </h1>
    </div>
    <div class="row mb-4">
        <div class="col-lg-6 col-xl-8 ilustracao">
            <img src="./assets/images/ilustra-home-ficai.jpg"
                alt="Ilustração de garoto estudando com formas geométricas e desenhos de itens escolares em volta"
                class="img-responsive">
        </div>

        <div class="col-lg-6 col-xl-4">
            <p>
                Você está acessando a FICAI 4.0, plataforma destinada ao registro, encaminhamento e sistematização de
                dados de estudantes que não estão na escola por infrequência, evasão ou não acesso. Trata-se de um
                instrumento existente desde 1997, que sofreu alterações e aprimoramentos ao longo do tempo, chegando à
                sua versão 4.0.
            </p>
            <p>
                Esta versão foi modernizada em termos tecnológicos, para permitir melhor usabilidade, assim como foi
                atualizada em termos conceituais/metodológicos. Assim, além dos tradicionais atores da FICAI (escolas,
                Conselho Tutelar e Ministério Público), o papel colaborativo da rede intersetorial terá visibilidade na
                garantia do direito à educação.
            </p>
            <p>
                Conheça a plataforma e faça parte dessa história!
            </p>
            <p>
                <strong>Acesso apenas para usuários autorizados.</strong>
            </p>

            <ng-container *ngIf="!isLogado">
                <button class="br-button login-govbr" (click)="login()">Entrar com <strong>gov.br</strong></button>
            </ng-container>
            <!-- <ng-container *ngIf="isLogado">.
                <button class="br-button is-primary" (click)="loginActive()">Acessar perfis</button>
            </ng-container> -->
        </div>
    </div>
    <div class="mt-5">
        <!-- <a href="http://ficaionline.procempa.com.br" target="_blank" rel="noopener nofollow noreferrer">
            Acesse o sistema antigo
            <span class="visually-hidden">(link abre em nova janela)</span>
        </a>
        <br>
        <small class="helper">Acesso apenas com Firefox e Microsoft Edge.</small> -->
        <a href="https://manuais.procempa.com.br/books/ficai-manual-do-usuario/page/tutoriais" target="_blank" rel="noopener nofollow noreferrer">
            Tutoriais FICAI 4.0
            <span class="visually-hidden">(link abre em nova janela)</span>
        </a>
        <br>
        <small class="helper">Confira o passo a passo das principais funcionalidades da plataforma</small>
    </div>

</div>


<ng-template #loading>
    <td colspan="5">
        <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Carregando</span>
            </div>
        </div>
    </td>
</ng-template>