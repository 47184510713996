import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-button-edit-form',
  templateUrl: './button-edit-form.component.html',
  styleUrls: ['./button-edit-form.component.scss']
})
export class ButtonEditFormComponent {
  @Output() eventCancel: EventEmitter<any> = new EventEmitter();
  @Output() eventUpdate: EventEmitter<any> = new EventEmitter();

  @Input() isValid!: boolean;
  faXmark = faXmark;

  cancel() {
    this.eventCancel.emit(true);
  }

  updateAluno() {
    this.eventUpdate.emit();
  }
}
