import { PaginationQuery } from './PaginationQuery';

export class PaginatedResult<T> {
  collectionSize: number;
  data: T[];
  totalPages: number;
  first?: boolean;
  last?: boolean;
  query: PaginationQuery;

  constructor() {
    this.collectionSize = 0;
    this.data = [];
    this.totalPages = 0;
    this.query = new PaginationQuery();
  }
}
