<footer class="d-flex justify-content-between">
  <a href="https://www.mprs.mp.br/">
    <img class="marca-mprs"
    src="/assets/images/marca-mprs.png"
    alt="Ministério Público do Rio Grande do Sul"
  />
  </a>
  
  <a href="https://www.procempa.com.br">
    <img
    src="/assets/images/assinatura.svg"
    alt="Desenvolvimento Procempa"
  />
  </a>
  
</footer>
